import React from 'react';
import { ApiCallState } from '../api/common';
import { Link } from 'react-router-dom';
import { ArrowRight, ArrowLeft, Diagram3, Search } from 'react-bootstrap-icons';
import { DatasetService, DatasetShortInfoDto } from '../api/generated';

export function ListDatasets() {
  const [page, setPage] = React.useState<number>(0);
  const [datasets, setDatasets] = React.useState<ApiCallState<DatasetShortInfoDto[]>>({});
  const pageSize = 20;

  // load datasets
  React.useEffect(() => {
    (async () => {
      return await DatasetService.datasetControllerListIds({ page, pageSize });
    })()
      .then((result) => setDatasets({ result }))
      .catch((err) => setDatasets({ error: `${err}` }));
  }, [page]);

  return (
    <div>
      <h1>Datasets</h1>
      {datasets.result?.length === 0 ? (
        'You do not have any records yet.'
      ) : (
        <div>
          <table className='table'>
            <thead>
              <tr>
                <th>name</th>
                <th>created at</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {datasets.result?.map((v) => (
                <tr key={v.name}>
                  <td>{v.name}</td>
                  <td>{v.createdAt}</td>
                  <td>
                    <Link className='btn btn-outline-primary' to={`${v.name}/item`} title='items'>
                      <Diagram3 />
                    </Link>
                    &nbsp;
                    <Link className='btn btn-outline-info' to={v.name} title='details'>
                      <Search />
                    </Link>
                    &nbsp;
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {!datasets.result || pageSize <= datasets.result?.length ? (
            <div className='mt-3'>
              <button className='btn btn-outline-secondary me-3' onClick={() => setPage(Math.max(0, page - 1))}>
                <ArrowLeft /> prev
              </button>{' '}
              {page}{' '}
              <button className='btn btn-outline-primary ms-3' onClick={() => setPage(page + 1)}>
                next <ArrowRight />
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
}
