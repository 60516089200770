import type { DatasetInfoDto, DatasetItemDto, DatasetShortInfoDto, ItemCountDto } from '../models';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export type TDataDatasetControllerListIds = {
  page?: number;
  pageSize?: number;
};
export type TDataDatasetControllerGetInfo = {
  datasetId: string;
};
export type TDataDatasetControllerListItems = {
  datasetId: string;
  page?: number;
  pageSize?: number;
};
export type TDataDatasetControllerItem = {
  datasetId: string;
  itemId: string;
};
export type TDataDatasetControllerItemField = {
  datasetId: string;
  fieldName: string;
  itemId: string;
};
export type TDataDatasetControllerItemCount = {
  datasetId: string;
};

export class DatasetService {
  /**
   * list datasets
   * List all available datasets (views and repos) with minimal info.
   * Datasets that are __not__ in a clean state (not completely created; partially deleted) are excluded
   * @returns DatasetShortInfoDto get minimal info for all available repositories
   * @throws ApiError
   */
  public static datasetControllerListIds(
    data: TDataDatasetControllerListIds = {}
  ): CancelablePromise<Array<DatasetShortInfoDto>> {
    const { page, pageSize } = data;
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dataset',
      query: {
        page,
        pageSize,
      },
      errors: {
        500: `unknown error`,
      },
    });
  }

  /**
   * get datasets info
   * Get detailed info about a dataset and it's dependencies
   * Note that references to or referencing datasets are __included__ in the *referencesTo* or *referencedBy*         arrays even if they are __not fully created__. This is because for datasets in creation we expect the new         reference to become factual in the future and thus a dataset even though it is not fully created already         blocks deletion of it's dependencies
   * @returns DatasetInfoDto
   * @throws ApiError
   */
  public static datasetControllerGetInfo(data: TDataDatasetControllerGetInfo): CancelablePromise<DatasetInfoDto> {
    const { datasetId } = data;
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dataset/{datasetId}',
      path: {
        datasetId,
      },
      errors: {
        404: `dataset not found`,
        500: `unknown error`,
      },
    });
  }

  /**
   * list items in the dataset
   * Get a list of items in a dataset
   * - string fields are returned as-is
   * - binary fields are resolved to the sha256 checksum of their content
   * - reference fields are resolved to the item id from the dataset they are referencing
   * @returns DatasetItemDto
   * @throws ApiError
   */
  public static datasetControllerListItems(
    data: TDataDatasetControllerListItems
  ): CancelablePromise<Array<DatasetItemDto>> {
    const { datasetId, page, pageSize } = data;
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dataset/{datasetId}/item',
      path: {
        datasetId,
      },
      query: {
        page,
        pageSize,
      },
      errors: {
        404: `dataset or item not found`,
        500: `unknown error`,
      },
    });
  }

  /**
   * get an item from the dataset
   * Receive the item from the dataset
   * Binary data fields contain the sha256 sum of their associated data. The data itself can be accessed through        the field get endpoint
   * @returns DatasetItemDto
   * @throws ApiError
   */
  public static datasetControllerItem(data: TDataDatasetControllerItem): CancelablePromise<DatasetItemDto> {
    const { datasetId, itemId } = data;
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dataset/{datasetId}/item/{itemId}',
      path: {
        datasetId,
        itemId,
      },
      errors: {
        404: `dataset not found`,
        500: `unknown error`,
      },
    });
  }

  /**
   * get data for a single field from an item
   * Download data form and item
   * For binary data the endpoint may return a redirect to where the data is hosted
   * @returns binary
   * @throws ApiError
   */
  public static datasetControllerItemField(data: TDataDatasetControllerItemField): CancelablePromise<Blob | File> {
    const { datasetId, fieldName, itemId } = data;
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dataset/{datasetId}/item/{itemId}/{fieldName}',
      path: {
        datasetId,
        itemId,
        fieldName,
      },
      errors: {
        404: `dataset not found`,
        500: `unknown error`,
      },
    });
  }

  /**
   * count items in the dataset
   * Get number of all committed items in the dataset
   * @returns ItemCountDto
   * @throws ApiError
   */
  public static datasetControllerItemCount(data: TDataDatasetControllerItemCount): CancelablePromise<ItemCountDto> {
    const { datasetId } = data;
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/dataset/{datasetId}/count',
      path: {
        datasetId,
      },
      errors: {
        404: `dataset not found`,
        500: `unknown error`,
      },
    });
  }
}
