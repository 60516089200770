import React from 'react';
import { ApiCallState } from '../api/common';
import styles from './AddRepo.module.scss';
import stylesMessageBox from './MessageBox.module.scss';
import { parse as yamlParse } from 'yaml';
import { AddRepoDto, CancelablePromise, RepoService } from '../api/generated';

export interface ApplyYamlResourceProps<R, T> {
  apiCallback: (data: R) => CancelablePromise<T>;
}

export function ApplyYamlResource<R, T>(props: ApplyYamlResourceProps<R, T>) {
  const [value, setValue] = React.useState<string>('---');
  const [apiCall, setApiCall] = React.useState<ApiCallState<T | false>>({});

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    const datasetDescription = yamlParse(value);
    (async () => {
      // setApiCall({ result: false });   # TODO: check ui logic

      const res = await props.apiCallback(datasetDescription);
      // setValue(yamlStringify(res));   # TODO: check ui logic
      return res;
    })()
      .then((result) => setApiCall({ result }))
      .catch((err) => setApiCall({ error: `${err['message']}` }));
  }

  return (
    <div>
      {/* indicate busy state */}
      {'result' in apiCall && apiCall.result === false && (
        <div className={`${stylesMessageBox.message_box} ${stylesMessageBox.busy}`}>busy...</div>
      )}
      {/* display api success */}
      {!!apiCall.result && (
        <div className={`${stylesMessageBox.message_box} ${stylesMessageBox.success}`}>Request successful</div>
      )}
      {/* display api error message */}
      {!!apiCall.error && (
        <div className={`${stylesMessageBox.message_box} ${stylesMessageBox.error}`}>{apiCall.error}</div>
      )}
      {/* Form to enter resource definition */}
      <form onSubmit={handleSubmit}>
        <textarea className={styles.resource_editor} value={value} onChange={(e) => setValue(e.target.value)} />
        <br />
        <input className='btn btn-primary' type='submit' value='Submit' />
      </form>
    </div>
  );
}

export function AddRepo() {
  return (
    <div>
      <h1>Add Repository</h1>
      <p>
        <i>Enter YAML repository description</i>
      </p>
      <ApplyYamlResource apiCallback={(data: AddRepoDto) => RepoService.repoControllerAdd({ requestBody: data })} />
    </div>
  );
}
