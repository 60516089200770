import React from 'react';
import { ApiCallState, blobToDataURL } from '../api/common';
import { useParams, Link } from 'react-router-dom';
import {
  DatasetService,
  DatasetReferenceFieldSchemaDto,
  DatasetBinaryFieldSchemaDto,
  DatasetInfoDto,
  DatasetItemDto,
} from '../api/generated';
import { DatasetFieldSchema } from '../api/api-type-helper';

interface BinaryDisplayProps {
  datasetId: string;
  itemId: string;
  fieldName: string;
  mimeType: string;
}

function BinaryDisplay(props: BinaryDisplayProps) {
  const [data, setData] = React.useState<ApiCallState<string>>({});
  React.useEffect(() => {
    (async () => {
      const blob = await DatasetService.datasetControllerItemField({
        itemId: props.itemId,
        datasetId: props.datasetId,
        fieldName: props.fieldName,
      });
      return await blobToDataURL(blob);
    })()
      .then((result) => {
        setData({ result });
      })
      .catch((err) => {
        console.error({ error: `${err.message}` });
      });
  }, [props.itemId, props.datasetId, props.fieldName]);

  if (data.result) {
    if (props.mimeType.startsWith('image/')) {
      return <img src={data.result} alt={props.itemId} />;
    } else {
      return <p>mime type not supported</p>;
    }
  }
  return <p>loading...</p>;
}

interface ItemFieldProps {
  schema: DatasetFieldSchema;
  value: string | number;
  datasetId: string;
  itemId: string;
}

function ItemField(props: ItemFieldProps) {
  const [displayBinary, setDisplayBinary] = React.useState<boolean>(false);

  switch (props.schema.type) {
    case 'string':
      return (
        <div>
          {props.schema.name}: {props.value}
        </div>
      );
    case 'reference':
      return (
        <div>
          {props.schema.name}:{' '}
          <Link to={`/dataset/${(props.schema as DatasetReferenceFieldSchemaDto).target}/item/${props.value}`}>
            {props.value}
          </Link>
        </div>
      );
    case 'binary':
      return (
        <div>
          {props.schema.name}:&nbsp;
          <button onClick={() => setDisplayBinary(!displayBinary)}>show</button>
          {displayBinary && (
            <BinaryDisplay
              datasetId={props.datasetId}
              itemId={props.itemId}
              fieldName={props.schema.name}
              mimeType={(props.schema as DatasetBinaryFieldSchemaDto).mime}
            />
          )}
          <ul>
            <li>sha256 hash: {props.value}</li>
            <li>mime type: {(props.schema as DatasetBinaryFieldSchemaDto).mime}</li>
          </ul>
        </div>
      );
    default:
      return (
        <div>
          {(props.schema as DatasetFieldSchema).name ? (props.schema as DatasetFieldSchema).name : 'unknown'}: NOT
          IMPLEMENTED
        </div>
      );
  }
}

export function ShowItem() {
  const { datasetId, itemId } = useParams();
  const [dataset, setDataset] = React.useState<ApiCallState<DatasetInfoDto>>({});
  const [item, setItem] = React.useState<ApiCallState<DatasetItemDto>>({});

  // load dataset info
  React.useEffect(() => {
    if (datasetId) {
      (async () => {
        return await DatasetService.datasetControllerGetInfo({ datasetId });
      })()
        .then((result) => setDataset({ result }))
        .catch((err) => setDataset({ error: `${err}` }));
    }
  }, [datasetId]);

  // load item
  React.useEffect(() => {
    if (datasetId && itemId) {
      (async () => {
        return await DatasetService.datasetControllerItem({ datasetId, itemId });
      })()
        .then((result) => setItem({ result }))
        .catch((err) => setItem({ error: `${err}` }));
    }
  }, [datasetId, itemId]);

  if (!dataset.result || !item.result) return null;

  return (
    <div>
      {dataset.result.schema.map((s) => (
        <ItemField
          key={s.name}
          schema={s}
          value={(item.result?.data || {})[s.name]}
          datasetId={datasetId as string}
          itemId={itemId as string}
        ></ItemField>
      ))}
    </div>
  );
}
