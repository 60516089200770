import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { NoPage } from './NoPage/NoPage';
import { MainLayout } from './MainLayout/MainLayout';
import { ListDatasets } from './ListDatasets/ListDatasets';
import { ListRepos } from './ListRepos/ListRepos';
import { ListViews } from './ListViews/ListViews';
import { ListItems } from './ListItems/ListItems';
import { ShowItem } from './ShowItem/ShowItem';
import { AddRepo } from './AddRepo/AddRepo';
import { AddView } from './AddView/AddView';
import { AddItem } from './AddItem/AddItem';
import { ShowDataset } from './ShowDataset/ShowDataset';
import { config } from './config/config';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { AuthProvider, withAuthenticationRequired } from 'react-oidc-context';
import { UserProvider } from './UserContext';

export interface AppRouterProps {
  basename?: string;
}

const userManager = new UserManager({
  authority: config.oauth.authority,
  client_id: config.oauth.clientId,
  redirect_uri: `${window.location.origin}${window.location.pathname}`,
  post_logout_redirect_uri: window.location.origin,
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
});

const onSigninCallback = () => {
  window.location.href = '/';
};

export function AppRouter(props: AppRouterProps) {
  const AuthedMainLayout = withAuthenticationRequired(MainLayout);

  return (
    <BrowserRouter basename={props.basename || ''}>
      <AuthProvider userManager={userManager} onSigninCallback={onSigninCallback}>
        <UserProvider>
          <Routes>
            <Route path='/' element={<AuthedMainLayout />}>
              <Route index element={<Navigate to='dataset/' />} />

              <Route path='dataset' element={<ListDatasets />} />
              <Route path='dataset/:datasetId' element={<ShowDataset />} />
              <Route path='dataset/:datasetId/item' element={<ListItems />} />
              <Route path='dataset/:datasetId/item/:itemId' element={<ShowItem />} />

              <Route path='view' element={<ListViews />} />
              <Route path='repo' element={<ListRepos />} />
              <Route path='repo/:repoId/additem' element={<AddItem />} />

              <Route path='addrepo' element={<AddRepo />} />
              <Route path='addview' element={<AddView />} />
            </Route>
            <Route path='*' element={<NoPage />} />
          </Routes>
        </UserProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
