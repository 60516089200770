import React from 'react';
import { useAuth } from 'react-oidc-context';
import { createContext, PropsWithChildren } from 'react';
import { OpenAPI } from './api/generated';

export interface UserContextProps {
  user?: {};
  reloadUser: () => void;
}

export const UserContext = createContext<UserContextProps>({ reloadUser: () => {} });

export function UserProvider(props: PropsWithChildren) {
  const { user: authUser, isAuthenticated, isLoading } = useAuth();
  const [reloadTrigger, setReloadTrigger] = React.useState<number>(0);

  function reloadUser() {
    setReloadTrigger((prev) => prev + 1);
  }

  OpenAPI.TOKEN = authUser?.access_token;
  React.useEffect(() => {
    OpenAPI.TOKEN = authUser?.access_token;
  }, [reloadTrigger, authUser, isAuthenticated, isLoading]);

  return (
    <UserContext.Provider value={{ user: authUser ? {} : undefined, reloadUser }}>
      {props.children}
    </UserContext.Provider>
  );
}
