import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';

export function Navbar() {
  const { signoutRedirect, isAuthenticated } = useAuth();
  return (
    <nav className='navbar sticky-top navbar-expand bg-body-tertiary'>
      <div className='container-fluid'>
        <Link className='navbar-brand' to='/'>
          <img src='/DSM-logo-680x610.png' alt='Bootstrap' width='45' />
        </Link>
        <ul className='navbar-nav me-auto mb-2'>
          <li className='nav-item'>
            <Link className='nav-link' to='/dataset/'>
              Datasets
            </Link>
          </li>
          <li className='nav-item'>
            <Link className='nav-link' to='/repo/'>
              Repos
            </Link>
          </li>
          <li className='nav-item'>
            <Link className='nav-link' to='/view/'>
              Views
            </Link>
          </li>
        </ul>
        <div className='hidden xl:flex items-center space-x-5'>
          <div className='hover:text-gray-200'>
            {!!isAuthenticated && (
              <button type='button' className='text-blue-800' onClick={() => signoutRedirect()}>
                Logout
              </button>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
