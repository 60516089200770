import type { AddViewDto, NamedResourceDto, ViewShortInfoDto } from '../models';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export type TDataViewControllerListIds = {
  page?: number;
  pageSize?: number;
};
export type TDataViewControllerAdd = {
  requestBody: AddViewDto;
};
export type TDataViewControllerDelete = {
  datasetId: string;
};

export class ViewService {
  /**
   * list views
   * List all available and ready views with minimal info. Similar to *list repositories*
   *
   * @returns ViewShortInfoDto get minimal info for all available views
   * @throws ApiError
   */
  public static viewControllerListIds(
    data: TDataViewControllerListIds = {}
  ): CancelablePromise<Array<ViewShortInfoDto>> {
    const { page, pageSize } = data;
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/view',
      query: {
        page,
        pageSize,
      },
    });
  }

  /**
   * create view
   * Create a new view
   * error/retry semantics are identical to *create repository*
   * @returns ViewShortInfoDto repository with a matching schema definition already exists, returns the existing repo
   * @throws ApiError
   */
  public static viewControllerAdd(data: TDataViewControllerAdd): CancelablePromise<ViewShortInfoDto> {
    const { requestBody } = data;
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/view',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        409: `repository with the same name exists but the schema differs to the one defined in this request, return the conflicting repo`,
        500: `unknown error`,
      },
    });
  }

  /**
   * delete view
   * Delete a view
   * error/retry semantics are identical to *delete repository*
   * @returns NamedResourceDto deletion successful
   * @throws ApiError
   */
  public static viewControllerDelete(data: TDataViewControllerDelete): CancelablePromise<NamedResourceDto> {
    const { datasetId } = data;
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/view/{datasetId}',
      path: {
        datasetId,
      },
      errors: {
        404: `view not found`,
        500: `unknown error`,
      },
    });
  }
}
