import React from 'react';
import { ApiCallState } from '../api/common';
import { useParams, Link } from 'react-router-dom';
import stylesMessageBox from '../AddRepo/MessageBox.module.scss';
import { DatasetService, RepoService, ViewService, DatasetInfoDto, ItemCountDto } from '../api/generated';

export function ShowDataset() {
  const { datasetId } = useParams();
  const [dataset, setDataset] = React.useState<ApiCallState<DatasetInfoDto>>({});
  const [count, setCount] = React.useState<ApiCallState<ItemCountDto>>({});
  const [apiCall, setApiCall] = React.useState<ApiCallState<Object | false>>({});

  // load dataset info
  React.useEffect(() => {
    if (datasetId) {
      (async () => {
        return await DatasetService.datasetControllerGetInfo({ datasetId });
      })()
        .then((result) => setDataset({ result }))
        .catch((err) => setDataset({ error: `${err}` }));
    }
  }, [datasetId]);

  // load item count
  React.useEffect(() => {
    if (datasetId) {
      (async () => {
        return await DatasetService.datasetControllerItemCount({ datasetId });
      })()
        .then((result) => setCount({ result }))
        .catch((err) => setCount({ error: `${err}` }));
    }
  }, [datasetId]);

  function handleDelete(event: React.MouseEvent | React.TouchEvent) {
    event.preventDefault();
    (async () => {
      setApiCall({ result: false });
      if (dataset.result?.view && datasetId) {
        return await ViewService.viewControllerDelete({ datasetId });
      } else if (dataset.result?.repo && datasetId) {
        return await RepoService.repoControllerDelete({ datasetId });
      } else {
        throw new Error('dataset is neiter view nor repo: invalid');
      }
    })()
      .then((result) => setApiCall({ result }))
      .catch((err) => setApiCall({ error: `${err['message']}` }));
  }

  if (!dataset.result) return null;

  return (
    <div>
      <h1>{dataset.result.name}</h1>
      {/* indicate busy state */}
      {'result' in apiCall && apiCall.result === false && (
        <div className={`${stylesMessageBox.message_box} ${stylesMessageBox.busy}`}>busy...</div>
      )}
      {/* display api success */}
      {!!apiCall.result && (
        <div className={`${stylesMessageBox.message_box} ${stylesMessageBox.success}`}>Request successful</div>
      )}
      {/* display api error message */}
      {!!apiCall.error && (
        <div className={`${stylesMessageBox.message_box} ${stylesMessageBox.error}`}>{apiCall.error}</div>
      )}

      <div>
        actions:{' '}
        <button className='btn btn-danger' onClick={handleDelete}>
          delete
        </button>
      </div>
      <div>
        creationTime: {dataset.result.createdAt}
        <br />
      </div>
      <div>
        itemCount: {count.result?.totalItems}
        <br />
      </div>
      <div>
        schema:<pre>{JSON.stringify(dataset.result.schema, null, 2)}</pre>
      </div>
      {dataset.result.view ? (
        <div>
          view:<pre>{JSON.stringify(dataset.result.view, null, 2)}</pre>
        </div>
      ) : null}
      <div>
        referencesTo:
        <ul>
          {dataset.result.referencesTo.map((v, idx) => (
            <li key={idx}>
              <Link to={`/dataset/${v}`}>{v}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div>
        referencedBy:
        <ul>
          {dataset.result.referencedBy.map((v, idx) => (
            <li key={idx}>
              <Link to={`/dataset/${v}`}>{v}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
